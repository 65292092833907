import React, { ReactNode, useMemo } from "react";
import {
  Info,
  LinearScale,
  Flaky,
  Balance,
  Tune,
  TextFields,
  ContentCopy,
} from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Select,
  SelectProps,
} from "@mui/material";
import {
  copyOptions,
  getLikertOptions,
  getMostRecentChoiceItem,
  getYesNoOptions,
  hasIdenticalOptions,
} from "../../Data";
import {
  FormItem,
  ItemOption,
  ItemType,
  Template,
  QuestionType,
} from "../../types";

export type TypeChangeHandler = (
  type: ItemType,
  options: ItemOption[],
  questionType?: QuestionType,
) => void;

enum ItemClass {
  Likert = "Likert",
  YesNo = "YesNo",
  Instructions = "Instructions",
  Text = "Text",
  Choice = "Choice",
  Points = "Points",
  Copy = "Copy",
  Blank = "Blank",
}

function getItemClass(classList: ClassList, item: FormItem): ItemClass {
  const itemClass = ItemClassLookup[item.itemType];
  if (itemClass !== ItemClass.Choice) {
    return itemClass;
  }

  if (hasIdenticalOptions(getLikertOptions(), item.options)) {
    return ItemClass.Likert;
  }

  if (hasIdenticalOptions(getYesNoOptions(), item.options)) {
    return ItemClass.YesNo;
  }

  if (
    classList[ItemClass.Copy] &&
    hasIdenticalOptions(
      classList[ItemClass.Copy].getOptions?.() ?? [],
      item.options,
    )
  ) {
    return ItemClass.Copy;
  }

  return ItemClass.Choice;
}

interface ItemClassFeatures {
  getOptions?: () => ItemOption[];
  icon: ReactNode;
  name: string;
  type: ItemType;
  questionType?: QuestionType;
}

type ClassList = Record<string, ItemClassFeatures>;

const ItemClassLookup: Record<ItemType, ItemClass> = {
  [ItemType.Instructions]: ItemClass.Instructions,
  [ItemType.Text]: ItemClass.Text,
  [ItemType.Choice]: ItemClass.Choice,
  [ItemType.Points]: ItemClass.Points,
  [ItemType.Blank]: ItemClass.Blank,
};

function getClassList(template: Template, ignoreId?: string): ClassList {
  const mostRecentChoiceItem = getMostRecentChoiceItem(template, ignoreId);

  const classList: ClassList = {
    [ItemClass.Likert]: {
      icon: <LinearScale />,
      type: ItemType.Choice,
      name: "Likert Scale",
      getOptions: getLikertOptions,
      questionType: QuestionType.Likert,
    },
    [ItemClass.Choice]: {
      icon: <Tune />,
      type: ItemType.Choice,
      name: "Custom Choices",
      getOptions: () => [],
      questionType: QuestionType.Generic,
    },
    [ItemClass.Text]: {
      icon: <TextFields />,
      type: ItemType.Text,
      name: "Free Text",
      getOptions: undefined,
    },
    [ItemClass.YesNo]: {
      icon: <Flaky />,
      type: ItemType.Choice,
      name: "Yes/No Choice",
      getOptions: getYesNoOptions,
      questionType: QuestionType.YesNo,
    },
    [ItemClass.Points]: {
      icon: <Balance />,
      type: ItemType.Points,
      name: "Points Allocation",
      getOptions: undefined,
    },
    [ItemClass.Instructions]: {
      icon: <Info />,
      type: ItemType.Instructions,
      name: "Instructions",
      getOptions: undefined,
    },
  };
  if (mostRecentChoiceItem) {
    classList[ItemClass.Copy] = {
      icon: <ContentCopy />,
      type: ItemType.Choice,
      name: "Copy Previous Scale",
      getOptions: () => copyOptions(mostRecentChoiceItem),
      questionType: QuestionType.Generic,
    };
  }
  return classList;
}

function ItemTypes({
  classList,
  onTypeSelect,
}: {
  classList: ClassList;
  onTypeSelect?: TypeChangeHandler;
}) {
  return Object.entries(classList).map(
    ([key, { name, icon, type, getOptions, questionType }]) => (
      <MenuItem
        key={key}
        value={key}
        onClick={
          onTypeSelect &&
          (() => onTypeSelect(type, getOptions?.() ?? [], questionType))
        }
      >
        <ListItemIcon
          sx={{
            ".MuiInputBase-input &": {
              display: "none",
            },
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText>{name}</ListItemText>
      </MenuItem>
    ),
  );
}

export function TypeSelect(
  props: SelectProps<string> & {
    item: FormItem;
    onTypeSelect: TypeChangeHandler;
    template: Template;
  },
) {
  const { onTypeSelect, item, template, ...remaining } = props;
  const classList = useMemo(
    () => getClassList(template, item.id),
    [template, item.id],
  );
  return (
    <Select
      {...remaining}
      value={getItemClass(classList, item)}
      onChange={(e) => {
        const { type, getOptions, questionType } = classList[e.target.value];
        onTypeSelect(type, getOptions?.() ?? [], questionType);
      }}
    >
      {ItemTypes({ classList })}
    </Select>
  );
}

export function TypeMenu(
  props: MenuProps & { onTypeSelect: TypeChangeHandler; template: Template },
) {
  const { onTypeSelect, template, ...remaining } = props;
  const classList = useMemo(() => getClassList(template), [template]);
  return <Menu {...remaining}>{ItemTypes({ classList, onTypeSelect })}</Menu>;
}
